import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  loggedIn: localStorage.getItem('access'),
  value: 0,
  ticketData: undefined,
  ticketAdults: 1,
  ticketChild: 0,
  ticketBabies: 0,
  ticketTarif: 'a',
  shopTicketCom: false,
  ticketId: '',
  filterAirlines: [],
  changeFilterAir: false,
  allAirlinesName: [],
  ticketDetail: [],
  filterDirect: 1,
  filterDirectChange: undefined,
  popularCitesName: '',
  popularCitesCode: '',
  popularCitesCountry: '',
  countTicket: 20,
  userInfo: [],
  currency: 'UZS',
  priceOrder: '',
  arrOrder: 0,
  depOrder: 0,
  durationOrder: 0,
  changeFilterDirect: false,
  orderToken: undefined,
}


export const slice = createSlice({
  name: 'AllSlice',
  initialState,
  reducers: {

    changeTicketData: (state, action) => {
      state.ticketData = action.payload
    },
    setTicketAdult: (state, action) => {
      state.ticketAdults = action.payload
    },
    setTicketChild: (state, action) => {
      state.ticketChild = action.payload
    },
    setTicketDetail: (state, action) => {
      state.ticketDetail = action.payload
    },

    setTicketBabies: (state, action) => {
      state.ticketBabies = action.payload
    },
    setTicketTarif: (state, action) => {
      state.ticketTarif = action.payload
    },
    setShopTicketCom: (state, action) => {
      state.shopTicketCom = action.payload
    },
    setTicketId: (state, action) => {
      state.ticketId = action.payload
    },
    addFilterAirlines: (state, action) => {
      state.filterAirlines = [...state.filterAirlines.filter(item => (item !== action.payload)), action.payload]
    },
    removeFilterAirlines: (state, action) => {
      state.filterAirlines = [...state.filterAirlines.filter(item => (item !== action.payload))]
    },
    clearFilterAirlines: (state, action) => {
      state.filterAirlines = []
    },
    checkChangeAir: (state, action) => {
      state.changeFilterAir = true
    },
    addFilterAirlinesName: (state, action) => {
      state.allAirlinesName = action.payload
    },
    clearFilterAirlinesName: (state, action) => {
      state.allAirlinesName = []
    },
    setFilterDirect: (state, action) => {
      state.filterDirect = action.payload
    },
    setFilterDirectChange: (state, action) => {
      state.filterDirectChange = action.payload
    },
    setPopularCities: (state, action) => {
      state.popularCitesName = action.payload
    },
    setPopularCitesCode: (state, action) => {
      state.popularCitesCode = action.payload
    },
    setPopularCitesCountry: (state, action) => {
      state.popularCitesCountry = action.payload
    },
    setCountTicket: (state, action) => {
      state.countTicket = state.countTicket + action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setCurrency: (state, action) => {
      state.currency = action.payload
    },
    setPriceOrder: (state, action) => {
      state.priceOrder = action.payload
    },
    setArrOrder: (state, action) => {
      state.arrOrder = action.payload
    },
    setDepOrder: (state, action) => {
      state.depOrder = action.payload
    },
    setDurationOrder: (state, action) => {
      state.durationOrder = action.payload
    },
    setChangeFilterDirect: (state, action) => {
      state.changeFilterDirect = action.payload
    },
    setOrderToken: (state, action) => {
      state.orderToken = action.payload
    },
  
  }
})

export const { changeTicketData, setTicketAdult, setTicketChild, setTicketBabies, setTicketTarif, setShopTicketCom, setTicketId, addFilterAirlines, removeFilterAirlines, checkChangeAir, addFilterAirlinesName, clearFilterAirlinesName, clearFilterAirlines, setFilterDirect, setFilterDirectChange, setPopularCities, setCountTicket, setUserInfo, setCurrency, setArrOrder, setDepOrder, setDurationOrder, setPriceOrder, setChangeFilterDirect, setOrderToken, setPopularCitesCode,setPopularCitesCountry} = slice.actions

export default slice.reducer 