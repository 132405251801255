import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const AllApi = createApi({
  reducerPath: "LoginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.mysafar.ru",
  }),

  endpoints: (builder) => ({
    searchAirports: builder.mutation({
      query: (airport) => ({
        url: "/avia/airports",
        method: "POST",
        body: airport,
      }),
    }),

    searchAirportsTo: builder.mutation({
      query: (airport) => ({
        url: "/avia/airports",
        method: "POST",
        body: airport,
      }),
    }),

    getRecommendation: builder.mutation({
      query: (recommendationBody) => ({
        url: "/avia/get-recommendations",
        method: "POST",
        body: recommendationBody,
        headers: {
          Authorization: localStorage.getItem("access") && `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    bookingCreate: builder.mutation({
      query: (bookBody) => ({
        url: "/avia/booking-create",
        method: "POST",
        body: bookBody,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    bookingConfirm: builder.mutation({
      query: (bookBody) => ({
        url: "/avia/booking-confirm",
        method: "POST",
        body: bookBody,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    paymentConfirm: builder.mutation({
      query: (paymentBody) => ({
        url: "/avia/payment-confirm",
        method: "POST",
        body: paymentBody,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    flightInfo: builder.mutation({
      query: (flightBody) => ({
        url: "/avia/get-flight-info",
        method: "POST",
        body: flightBody,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    popularCites: builder.mutation({
      query: () => ({
        url: "/main/popcites",
        method: "POST",
      }),
    }),

    searchHistory: builder.query({
      query: () => ({
        url: "/main/search-history",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    searchHistoryPost: builder.mutation({
      query: (historyDate) => ({
        url: "/main/search-history_post",
        method: "POST",
        body: historyDate,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    getTicketPrice: builder.mutation({
      query: (ticketPriceBody) => ({
        url: "/avia/one-ticket-price",
        method: "POST",
        body: ticketPriceBody,
      }),
    }),

    switchUpDown: builder.mutation({
      query: () => ({
        url: "admina/get-avia-service-switcher",
        method: "GET",
      }),
    }),

    getReceivedTicketData: builder.mutation({
      query: (id) => ({
        url: `/avia/ticket-data/${id}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    getCheque: builder.mutation({
      query: () => ({
        url: "main/cheques",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

  }),
});

export const { useSearchAirportsMutation, useSearchAirportsToMutation, useGetRecommendationMutation, useBookingCreateMutation, useBookingConfirmMutation, usePaymentConfirmMutation, useFlightInfoMutation, usePopularCitesMutation, useSearchHistoryQuery, useSearchHistoryPostMutation, useGetTicketPriceMutation, useSwitchUpDownMutation,useGetChequeMutation, useGetReceivedTicketDataMutation } = AllApi;
