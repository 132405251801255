import React from 'react'
import './loader.css'
export default function Loader() {
  return (
    <div className='loader-container'>
      <div className="loader">
        <div className='plane-container'>
          <div className="plane">
            <img loading="lazy" src="https://zupimages.net/up/19/34/4820.gif" className="plane-img" />
          </div>
          <div className="earth-wrapper">
            <div className="earth"></div>
          </div>
        </div>
      </div>
    </div>
  )
}