import React, { useEffect, useState, Suspense } from "react";
import { Contexts } from "./contexts/Contexts";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import './Grid/Grid.css';
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/uz';
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import { useSwitchUpDownMutation } from "./RTKQueryApi/AllApi";
import { useSelector } from "react-redux";
import Loader from "./components/Loader/Loader";

// Lazy load components
const Navbar = React.lazy(() => import("./components/Navbar/Navbar"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const Home = React.lazy(() => import("./pages/Home/Home"));
const Profile = React.lazy(() => import("./pages/profile/Profile"));
const ShopTicket = React.lazy(() => import("./components/ShopTicket/ShopTicket"));
const BasicModal = React.lazy(() => import("./components/Modal/Modal"));
const UzCardPayment = React.lazy(() => import("./pages/Uz-card-payment/UzCardPayment"));
const AboutUs = React.lazy(() => import("./pages/aboutUs/AboutUs"));
const Sale = React.lazy(() => import("./pages/Sale/Sale"));
const Faq = React.lazy(() => import("./pages/faq/Faq"));
const GetTicket = React.lazy(() => import("./components/ShopTicket/GetTicket"));


function App() {
  const [open, setOpen] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [openResetPas, setOpenResetPas] = useState(false);
  const [show, setShow] = useState(false);
  const [langData, setLangData] = useState({});
  const [count, setCount] = useState(10);
  const [loginModal, setLoginModal] = useState(false);
  const [lang, setLang] = useState("ru");
  const [itemId, setItemId] = useState(1);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [myAirlinesToInpErr, setMyAirlinesToInpErr] = useState(false);
  const [getRecLoad, setGetRecLoad] = useState(false);
  const [getRecSuc, setGetRecSuc] = useState(false);

  const [switchUpDown, { data: switchUpDownData, isSuccess: switchUpDownSuc }] = useSwitchUpDownMutation();
  const location = useLocation();
  const hideNavbarAndFooter = location.pathname.startsWith('/payment');

  moment.locale(lang);

  const loggedIn = useSelector((state) => state.AllSlice.loggedIn);

  useEffect(() => {
    if (window.location.href === 'https://2aqtzt5.257.cz/' || window.location.href === 'https://2aqtzt5.257.cz') {
      window.location.href = 'https://mysafar.uz/';
    }
  }, []);

  useEffect(() => {
    switchUpDown();
  }, []);

  useEffect(() => {
    if (switchUpDownSuc) {
      setIsMaintenanceMode(switchUpDownData.value === '0');
    }
  }, [switchUpDownSuc, switchUpDownData]);

  if (isMaintenanceMode) {
    return (
      <div className="flex Down-container h-[100vh] items-center justify-center text-[30px] text-white">
        {lang === 'en' &&
          <div className="flex items-center flex-col">
            <h1 className="text-[25px] lg:text-[50px]">Technical works are being carried out on the website.</h1>
            <h1 className="text-[25px] lg:text-[50px]"> Our website will be launched soon</h1>
          </div>
        }
        {lang === 'uz' &&
          <div className="flex items-center flex-col">
            <h1 className="text-[25px] lg:text-[50px]">Web saytda texnik ishlar olib borilmoqda. </h1>
            <h1 className="text-[25px] lg:text-[50px]">Tez orada web saytimiz ishga tushadi</h1>
          </div>
        }
        {lang === 'ru' && (
          <div className="flex items-center flex-col">
            <h1 className="text-[25px] lg:text-[50px]"> Наш сайт скоро будет запущен</h1>
            <h5 className="text-[12px] lg:text-[20px]">На сайте ведутся технические работы.</h5>
          </div>
        )}
      </div>
    );
  }

  return (
    <Contexts.Provider value={{
      open, setOpen, show, setShow, loginModal, setLoginModal, openForgot, setOpenForgot,
      openResetPas, setOpenResetPas, langData, setLangData, count, setCount, lang, setLang,
      itemId, setItemId, myAirlinesToInpErr, setMyAirlinesToInpErr,
      getRecLoad, setGetRecLoad, getRecSuc, setGetRecSuc
    }}>
      <Suspense fallback={<Loader />}>
        {!hideNavbarAndFooter || !loggedIn ? <Navbar /> : ''}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/search/:id' element={<Home />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/sale" element={<Sale />} />
          <Route path="/faq" element={<Faq />} />
          <Route element={<ProtectedRoutes />}>
            <Route path='/order/:id' element={<ShopTicket />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/payment/:id/:id" element={<UzCardPayment />} />
            <Route path="/getTicket/:id" element={<GetTicket />} />
          </Route>
          <Route path='*' element={<Navigate to="/"/>} />
        </Routes>
        <BasicModal />
        {!hideNavbarAndFooter && <Footer />}
      </Suspense>
    </Contexts.Provider >
  );
}

export default App;
