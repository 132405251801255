import React, { useContext } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { Contexts } from "../contexts/Contexts";


const ProtectedRoutes = () => {
  const loggedIn = localStorage.getItem("access")
  const { setLoginModal, open, openForgot } = useContext(Contexts);
  return loggedIn ? <Outlet /> : !open && !openForgot && setLoginModal(true);
};
export default ProtectedRoutes;